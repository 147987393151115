import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC3qN3oq6abb1mRYT7QJc8mrNHtH2MIivo",

  authDomain: "unitydocs-5652d.firebaseapp.com",

  projectId: "unitydocs-5652d",

  storageBucket: "unitydocs-5652d.firebasestorage.app",

  messagingSenderId: "527196810800",

  appId: "1:527196810800:web:0f99d9c223cba6b87dc7f5",

  measurementId: "G-R8TK81D8RW",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

const signInWithGoogle = () => {
  return signInWithPopup(auth, provider);
};

export { auth, signInWithGoogle };
