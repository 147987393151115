import React, { useState, useEffect, useMemo } from "react";
import {
  Search,
  Menu,
  Plus,
  Settings,
  Edit2,
  X,
  ChevronDown,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { toast } from "react-hot-toast";
import ReactMarkdown from "react-markdown";

// UserAvatar Component
const UserAvatar = ({ name, className }) => {
  const initials = name
    .split(" ")
    .map((word) => word[0])
    .join("")
    .toUpperCase()
    .slice(0, 2);

  return (
    <div
      className={`flex items-center justify-center rounded-full bg-purple-100 text-purple-700 font-medium ${className}`}
    >
      {initials}
    </div>
  );
};

// Settings Modal Component
const SettingsModal = ({ isOpen, onClose, user }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-sm p-6">
        <div className="flex items-center justify-between border-b pb-4">
          <h2 className="text-xl font-semibold">User Settings</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={20} />
          </button>
        </div>
        <div className="py-4">
          <div className="flex items-center space-x-4 mb-6">
            <UserAvatar name={user?.name || ""} className="w-16 h-16 text-xl" />
            <div>
              <h3 className="font-medium text-lg">{user?.name}</h3>
              <p className="text-sm text-gray-500">{user?.email}</p>
            </div>
          </div>
          <div className="space-y-4">
            <div className="border rounded-lg p-4">
              <h4 className="text-sm font-medium text-gray-500 mb-1">
                Account Type
              </h4>
              <p className="text-sm">Free Plan</p>
            </div>
            <div className="border rounded-lg p-4">
              <h4 className="text-sm font-medium text-gray-500 mb-1">
                Storage Used
              </h4>
              <p className="text-sm">0.2 MB of 1 GB</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Main Editor Component
const EditorPage = () => {
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [user, setUser] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [documentTitle, setDocumentTitle] = useState("Untitled");
  const [searchQuery, setSearchQuery] = useState("");
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const auth = getAuth();

  // Filter documents based on search query
  const filteredDocuments = useMemo(() => {
    return documents.filter((doc) =>
      doc.title.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [documents, searchQuery]);

  // Auth verification and user data loading
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (!currentUser) {
        navigate("/");
        toast.error("Please sign in to access the editor");
        return;
      }

      const userObj = {
        email: currentUser.email,
        name: currentUser.displayName || currentUser.email.split("@")[0],
      };

      localStorage.setItem("user", JSON.stringify(userObj));
      setUser(userObj);
      loadDocuments(currentUser.email);
    });

    return () => unsubscribe();
  }, [navigate]);

  // Load documents from localStorage based on user email
  const loadDocuments = (userEmail) => {
    const savedDocs = localStorage.getItem("documents");
    if (savedDocs) {
      const parsedDocs = JSON.parse(savedDocs);
      const userDocs = parsedDocs.filter((doc) => doc.userId === userEmail);
      setDocuments(userDocs);
    }
  };

  // Handle document title change
  const handleTitleChange = (newTitle) => {
    if (!selectedDoc) return;

    const allDocs = JSON.parse(localStorage.getItem("documents") || "[]");
    const updatedDocs = allDocs.map((doc) =>
      doc.id === selectedDoc.id
        ? { ...doc, title: newTitle, updatedAt: new Date().toISOString() }
        : doc,
    );

    localStorage.setItem("documents", JSON.stringify(updatedDocs));
    setDocuments(updatedDocs.filter((doc) => doc.userId === user.email));
    setSelectedDoc({ ...selectedDoc, title: newTitle });
    setDocumentTitle(newTitle);
    setIsEditingTitle(false);
  };

  // Handle sign out
  const handleSignOut = async () => {
    try {
      await auth.signOut();
      localStorage.removeItem("user");
      navigate("/");
      toast.success("Signed out successfully");
    } catch (error) {
      toast.error("Error signing out");
    }
  };

  // Save document
  const saveDocument = () => {
    if (!selectedDoc) {
      const newDoc = {
        id: Date.now(),
        title: documentTitle,
        content: content,
        updatedAt: new Date().toISOString(),
        userId: user.email,
      };
      const allDocs = JSON.parse(localStorage.getItem("documents") || "[]");
      const newDocs = [...allDocs, newDoc];
      localStorage.setItem("documents", JSON.stringify(newDocs));

      setDocuments([...documents, newDoc]);
      setSelectedDoc(newDoc);
      toast.success("Document created successfully");
    } else {
      const allDocs = JSON.parse(localStorage.getItem("documents") || "[]");
      const updatedDocs = allDocs.map((doc) =>
        doc.id === selectedDoc.id
          ? {
              ...doc,
              title: documentTitle,
              content: content,
              updatedAt: new Date().toISOString(),
            }
          : doc,
      );
      localStorage.setItem("documents", JSON.stringify(updatedDocs));
      setDocuments(updatedDocs.filter((doc) => doc.userId === user.email));
      setSelectedDoc({ ...selectedDoc, content, title: documentTitle });
      toast.success("Document saved successfully");
    }
  };

  // Create new document
  const createNewDocument = () => {
    const newDoc = {
      id: Date.now(),
      title: "Untitled",
      content:
        "# Start writing...\n\nThis document supports markdown formatting.",
      updatedAt: new Date().toISOString(),
      userId: user.email,
    };

    const allDocs = JSON.parse(localStorage.getItem("documents") || "[]");
    const newDocs = [...allDocs, newDoc];
    localStorage.setItem("documents", JSON.stringify(newDocs));

    setDocuments([...documents, newDoc]);
    setSelectedDoc(newDoc);
    setContent(newDoc.content);
    setDocumentTitle("Untitled");
    toast.success("New document created");
  };

  if (!user) {
    return null;
  }

  return (
    <div className="h-screen flex bg-white">
      {/* Sidebar */}
      <div className="w-64 bg-gray-50 border-r border-gray-200 flex flex-col">
        {/* User Profile Section */}
        <div className="p-4 border-b border-gray-200">
          <div className="flex items-center space-x-3">
            <UserAvatar name={user.name} className="w-8 h-8" />
            <div className="flex-1">
              <h3 className="text-sm font-medium text-gray-900">{user.name}</h3>
              <p className="text-xs text-gray-500">{user.email}</p>
            </div>
            <button
              onClick={() => setIsSettingsOpen(true)}
              className="text-gray-400 hover:text-gray-600"
            >
              <Settings size={16} />
            </button>
          </div>
        </div>

        {/* Search Bar */}
        <div className="p-4">
          <div className="relative">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search documents..."
              className="w-full px-4 py-2 pl-10 text-sm bg-white border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
            />
            <Search
              className="absolute left-3 top-2.5 text-gray-400"
              size={16}
            />
          </div>
        </div>

        {/* Documents List */}
        <div className="flex-1 overflow-y-auto">
          <div className="px-4 py-2 flex items-center justify-between">
            <span className="text-sm font-medium text-gray-600">Documents</span>
            <button
              onClick={createNewDocument}
              className="p-1 hover:bg-gray-200 rounded"
            >
              <Plus size={16} className="text-gray-600" />
            </button>
          </div>
          <div className="space-y-1 px-2">
            {filteredDocuments.map((doc) => (
              <button
                key={doc.id}
                onClick={() => {
                  setSelectedDoc(doc);
                  setContent(doc.content);
                  setDocumentTitle(doc.title);
                }}
                className={`w-full px-3 py-2 text-left rounded-lg transition-colors ${
                  selectedDoc?.id === doc.id
                    ? "bg-purple-100 text-purple-900"
                    : "hover:bg-gray-200 text-gray-700"
                }`}
              >
                <div className="text-sm font-medium truncate">{doc.title}</div>
                <div className="text-xs text-gray-500">
                  Updated {new Date(doc.updatedAt).toLocaleDateString()}
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col bg-white">
        {/* Top Bar */}
        <div className="h-14 border-b border-gray-200 px-4 flex items-center justify-between bg-white">
          <div className="flex items-center space-x-4">
            <button className="p-2 hover:bg-gray-100 rounded-lg">
              <Menu size={20} className="text-gray-600" />
            </button>
            <div className="flex items-center space-x-2">
              {isEditingTitle ? (
                <input
                  type="text"
                  value={documentTitle}
                  onChange={(e) => setDocumentTitle(e.target.value)}
                  onBlur={() => handleTitleChange(documentTitle)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleTitleChange(documentTitle);
                    }
                  }}
                  className="text-lg font-medium text-gray-900 border-b-2 border-purple-500 focus:outline-none"
                  autoFocus
                />
              ) : (
                <div className="flex items-center space-x-2">
                  <h1 className="text-lg font-medium text-gray-900">
                    {documentTitle}
                  </h1>
                  <button
                    onClick={() => setIsEditingTitle(true)}
                    className="p-1 hover:bg-gray-100 rounded"
                  >
                    <Edit2 size={14} className="text-gray-400" />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center space-x-3">
            <button
              onClick={() => setIsPreviewMode(!isPreviewMode)}
              className={`px-4 py-1.5 text-sm font-medium rounded-lg ${
                isPreviewMode
                  ? "bg-purple-100 text-purple-700"
                  : "text-gray-700 hover:bg-gray-100"
              }`}
            >
              {isPreviewMode ? "Edit" : "Preview"}
            </button>
            <button
              onClick={saveDocument}
              className="px-4 py-1.5 text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 rounded-lg"
            >
              Save
            </button>
          </div>
        </div>

        {/* Editor/Preview */}
        <div className="flex-1 overflow-y-auto">
          <div className="max-w-3xl mx-auto py-8 px-4">
            {isPreviewMode ? (
              <div className="prose max-w-none">
                <ReactMarkdown>{content}</ReactMarkdown>
              </div>
            ) : (
              <textarea
                value={content}
                onChange={(e) => setContent(e.target.value)}
                className="w-full h-full min-h-[500px] text-gray-900 text-lg resize-none focus:outline-none font-mono"
                placeholder="Start writing..."
              />
            )}
          </div>
        </div>

        {/* Status Bar */}
        <div className="h-8 border-t border-gray-200 px-4 flex items-center justify-between text-xs text-gray-500 bg-white">
          <div>
            Last saved:{" "}
            {selectedDoc?.updatedAt
              ? new Date(selectedDoc.updatedAt).toLocaleString()
              : "Never"}
          </div>
          <div>Markdown supported</div>
        </div>
      </div>

      {/* Settings Modal */}
      <SettingsModal
        isOpen={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        user={user}
      />
    </div>
  );
};

export default EditorPage;
