import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithGoogle } from "./firebase";
import { toast } from "react-hot-toast";

const backgroundStyles = {
  gridPattern: {
    backgroundImage: `linear-gradient(to right, #e5e7eb 1px, transparent 1px),
      linear-gradient(to bottom, #e5e7eb 1px, transparent 1px)`,
    backgroundSize: "64px 64px",
  },
  floatingAnimation1: {
    animation: "slowFloat 15s infinite ease-in-out",
  },
  floatingAnimation2: {
    animation: "slowFloat 18s infinite ease-in-out reverse",
    animationDelay: "-9s",
  },
};

export default function LandingPage() {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleGoogleLogin = async () => {
    setIsLoading(true);
    try {
      const result = await signInWithGoogle();
      const userInfo = {
        email: result.user.email,
        name: result.user.displayName,
        photoURL: result.user.photoURL,
        uid: result.user.uid,
      };
      localStorage.setItem("user", JSON.stringify(userInfo));
      toast.success("Successfully logged in!");
      navigate("/editor");
    } catch (err) {
      console.error("Google login failed", err);
      toast.error(err.message || "Failed to sign in with Google");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-white">
      {/* Enhanced Background Elements */}
      <div className="fixed inset-0 -z-10">
        {/* Base gradient */}
        <div className="absolute inset-0 bg-gradient-to-br from-gray-50 to-white" />

        {/* Animated gradient circles */}
        <div
          className="absolute -top-1/2 -right-1/2 w-full h-full rounded-full bg-gradient-to-br from-purple-100/40 to-blue-100/40 blur-3xl"
          style={backgroundStyles.floatingAnimation1}
        />
        <div
          className="absolute -bottom-1/2 -left-1/2 w-full h-full rounded-full bg-gradient-to-br from-blue-100/40 to-purple-100/40 blur-3xl"
          style={backgroundStyles.floatingAnimation2}
        />

        {/* Subtle grid pattern overlay */}
        <div
          className="absolute inset-0 opacity-[0.015]"
          style={backgroundStyles.gridPattern}
        />
      </div>

      {/* Navbar */}
      <nav
        className={`fixed w-full z-50 transition-all duration-300 ${
          isScrolled
            ? "bg-white/80 backdrop-blur-lg shadow-md"
            : "bg-transparent"
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center">
              <span className="text-2xl font-bold bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
                UnityDocs
              </span>
            </div>
            <div className="hidden md:flex items-center space-x-8">
              <a
                href="#features"
                className="text-gray-600 hover:text-gray-900 transition-colors"
              >
                Features
              </a>
              <a
                href="#pricing"
                className="text-gray-600 hover:text-gray-900 transition-colors"
              >
                Pricing
              </a>
              <a
                href="#testimonials"
                className="text-gray-600 hover:text-gray-900 transition-colors"
              >
                Testimonials
              </a>
              <button
                onClick={handleGoogleLogin}
                disabled={isLoading}
                className={`px-4 py-2 rounded-lg transition-all 
    ${isLoading ? "opacity-70 cursor-not-allowed" : "hover:scale-105 hover:shadow-lg"} 
    bg-gradient-to-r from-purple-600 to-blue-500 text-white`}
              >
                {isLoading ? "Signing in..." : "Sign in with Google"}
              </button>{" "}
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="pt-32 pb-20 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="text-center">
            <h1 className="text-5xl md:text-6xl font-bold text-gray-900 mb-6">
              Manage Your Documents
              <span className="block bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent">
                With Ease
              </span>
            </h1>
            <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
              A powerful document management system that helps you organize,
              edit, and collaborate on your documents in real-time.
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={handleGoogleLogin}
                disabled={isLoading}
                className={`px-8 py-4 rounded-lg transition-all duration-300 
    ${isLoading ? "opacity-70 cursor-not-allowed" : "hover:scale-105 hover:shadow-lg"} 
    bg-gradient-to-r from-purple-600 to-blue-500 text-white`}
              >
                {isLoading ? "Signing in..." : "Sign up with Google"}
              </button>
              <button className="px-8 py-4 rounded-lg border-2 border-gray-300 text-gray-700 hover:border-gray-400 hover:text-gray-900 transition-all duration-300 hover:shadow-lg">
                Watch Demo
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* Features Section */}
      <section id="features" className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900">
              Everything you need to manage your documents
            </h2>
            <p className="mt-4 text-gray-600">
              Powerful features to help you take control of your documentation
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                title: "Real-time Collaboration",
                description:
                  "Work together with your team in real-time. See changes as they happen.",
                icon: "🤝",
              },
              {
                title: "Version Control",
                description:
                  "Track changes and revert to previous versions whenever needed.",
                icon: "📝",
              },
              {
                title: "Secure Storage",
                description:
                  "Your documents are encrypted and securely stored in the cloud.",
                icon: "🔒",
              },
            ].map((feature, index) => (
              <div
                key={index}
                className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
              >
                <div className="text-4xl mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="pricing" className="py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900">
              Simple, transparent pricing
            </h2>
            <p className="mt-4 text-gray-600">
              No hidden fees. Choose the plan that's right for you.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                name: "Basic",
                price: "Free",
                features: ["5 Documents", "Basic Editing", "1 User"],
              },
              {
                name: "Pro",
                price: "$9.99/mo",
                features: [
                  "Unlimited Documents",
                  "Advanced Editing",
                  "5 Users",
                  "Version History",
                ],
              },
              {
                name: "Enterprise",
                price: "Custom",
                features: [
                  "Unlimited Everything",
                  "Priority Support",
                  "Custom Features",
                ],
              },
            ].map((plan, index) => (
              <div
                key={index}
                className={`bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 ${
                  index === 1 ? "border-2 border-purple-500" : ""
                }`}
              >
                <h3 className="text-2xl font-bold mb-4">{plan.name}</h3>
                <div className="text-4xl font-bold mb-6">{plan.price}</div>
                <ul className="space-y-3 mb-8">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="flex items-center">
                      <svg
                        className="w-5 h-5 text-green-500 mr-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
                <button
                  className={`w-full py-3 rounded-lg transition-all duration-300 ${
                    index === 1
                      ? "bg-gradient-to-r from-purple-600 to-blue-500 text-white hover:from-purple-700 hover:to-blue-600"
                      : "border-2 border-gray-300 text-gray-700 hover:border-gray-400"
                  }`}
                >
                  Get Started
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section id="testimonials" className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900">
              What our users say
            </h2>
            <p className="mt-4 text-gray-600">
              Trusted by thousands of users worldwide
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                name: "Sarah Johnson",
                role: "Product Manager",
                content:
                  "UnityDocs has transformed how our team collaborates on documents. It's incredibly intuitive and powerful.",
              },
              {
                name: "Mike Thompson",
                role: "Software Engineer",
                content:
                  "The version control features are a game-changer. I can track changes and revert when needed easily.",
              },
              {
                name: "Emily Chen",
                role: "Content Creator",
                content:
                  "I love how easy it is to organize and find my documents. The search functionality is amazing!",
              },
            ].map((testimonial, index) => (
              <div
                key={index}
                className="bg-white p-8 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <p className="text-gray-600 mb-6">"{testimonial.content}"</p>
                <div>
                  <div className="font-semibold">{testimonial.name}</div>
                  <div className="text-gray-500">{testimonial.role}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20">
        <div className="max-w-4xl mx-auto text-center px-4">
          <h2 className="text-3xl font-bold mb-8">Ready to get started?</h2>
          <button
            onClick={() => navigate("/signup")}
            className="px-8 py-4 rounded-lg bg-gradient-to-r from-purple-600 to-blue-500 text-white hover:from-purple-700 hover:to-blue-600 transition-all duration-300 hover:shadow-lg transform hover:scale-105"
          >
            Start Your Free Trial
          </button>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-xl font-bold mb-4">UnityDocs</h3>
              <p className="text-gray-400">
                Making document management simple and efficient.
              </p>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Product</h4>
              <ul className="space-y-2">
                <li>
                  <a
                    href="#"
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    Features
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    Pricing
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    Tutorial
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Company</h4>
              <ul className="space-y-2">
                <li>
                  <a
                    href="#"
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    Careers
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Legal</h4>
              <ul className="space-y-2">
                <li>
                  <a
                    href="#"
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    Privacy
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    Terms
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-gray-400 hover:text-white transition-colors"
                  >
                    Security
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-12 pt-8 border-t border-gray-800 text-center text-gray-400">
            <p>© 2024 UnityDocs. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}
